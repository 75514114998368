
var is_debug = true;

function popupAlert(type, message ){
  // alert(message);
  $('.message').html(message);
  $('.alert.alert-' + type ).fadeIn();

  setTimeout(function() {
    $('.alert').fadeOut();
  }, 7000 );
}

function debug(value){
  if (is_debug == true){
    console.log(value);
  }
}



$(function() {

  
  var frm, modal_holder_selector, modal_selector;
  modal_holder_selector = '#modal-holder';
  modal_selector = '.modal-responder';


  // EVENT CLICK HANDLER ON SLINK BUTTON OR a-href
  // EXAMPLE USAGE: %slink.btn.btn-outline-primary{url: new_location_path, slink: :true, isModal: :true, data: slink_data(modal: "modal")}
  $(document).on('click', '.silence-link', function(e) {
    
    e.preventDefault();
    debug("slink_trigered"  +  $(this).attr('data'));

    holder = $(this).attr('holder');
    url = $(this).attr('href');
    method = $(this).attr('method');
    isModal = $(this).attr('isModal');
    debug("modal " + isModal)
    request_data = JSON.parse( $(this).attr('data') );

    // USING AJAX FOR REQUEST
    $.ajax({
      type: method,
      url: url,
      data: request_data ,
      success: function(data) {
        debug("debug: " + "Request Success!!");
        if(isModal=="true"){
          // $(modal_selector).modal('toggle');
          $(modal_holder_selector).html(data).find(modal_selector).modal();
        }
        else{
          $(holder).html(data);
        }
      },
      error: function(data) {
        console.log("Error" + data);
      }
    });

  });


  // SILENCE-LINK FORM SUMITION
  // EXAMPLE USAGE:
  //** <form action="url_to_request" method="get" responseHolder="#id_of_html_holder" silenceForm="true" class="what-ever sform" > */
  $(document).on('submit', 'form[silence_form="true"]', function(e) {
    debug("Sform Submit Triggered");
    e.preventDefault();
    response_holder =  $(this).attr('response_holder')
    currentForm = $(this);
    var data = $(this).serializeArray();
    data.push({name: 'is_silence_request', value: 1 });

    console.log(data)
    $.ajax({
      type: $(this).attr('method'),
      url: $(this).attr('action'),
      data: $.param(data),
      success: function(data) {
        if (data.status === 'error') {
          alert(data.message);
          $(modal_holder_selector).find('.modal-body').html(data.html);
        } else {
          currentForm.get(0).reset();
          $(response_holder).html(data.html);
          $(modal_selector).modal('hide');
        }
      },
      error: function(data) {}
    });
  });

  return ;
});