$(function() {
  
  $(document).on("ready", function(){
    console.log("Mini Tree Loaded");
    
    

  });

});

