// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import "channels"

import "bootstrap"
import "jquery"
import 'select2'
import 'select2/dist/css/select2.css'


global.$ = require('jquery')
window.jQuery = require('jquery')

require("packs/mini_tree")
require("packs/app_component")
require("packs/silence_link")
require("packs/adminlte.min")



Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", function() {
  
  window.getReady()

});


window.getReady =function(){
  // Testing for check even load
  // console.log("Get Ready x")

  // SELECT2 
  $('.select2').select2()
  // $('.tagsinput').tagsinput()

  setSelectedTree();

}

// ##########################
// TREE LIST HANDLER SECTION
function setSelectedTree(){
  var queryString = window.location.search;
  var urlParams = new URLSearchParams(queryString);
  var treeValue =  urlParams.get('treeValue');
  if(parseInt(treeValue) > 0){
    $('.tri-key').removeClass('selected');
    $('.tri-key[tree-value='+treeValue+']').addClass('selected');
  }
}

// ##########################
// SET TRIGGER OF TRI-KEY
$(document).on('click','.tri-key' ,function(e){
  var treeValue=$(this).attr('tree-value');

  console.log("click Triggered - " + treeValue);
  e.stopPropagation()

  var current_url = window.location.pathname
  Turbolinks.visit( current_url + "?treeValue=" + treeValue );
  // window.location.assign(current_url + "?treeValue=" + treeValue )
});





